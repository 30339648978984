<template>
  <div>
    <!-- :left-arrow="true" -->
    <titleNav title="支付成功" />
    <div class="divse">
      <div class="submit_warp">
        <img src="@/assets/images/dui.png" alt="" />
        <div>支付成功</div>
        <div class="submine">
          <van-button
            size="large"
            @click="submitHnadn"
            style="border: 1px solid rgb(24 180 237); color: rgb(24, 180, 237)"
            >完成</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import { Button } from "vant";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    submitHnadn() {
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped lang="scss">
.divse {
    width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.submit_warp {
    width: 80%;
  text-align: center;
  font-size: 16px;

  img {
    margin: 20px 0;
    width: 80px;
    text-align: center;
  }
  .conent {
    padding: 20px 0;
    width: 200px;
    font-size: 14px;
    margin: 0 auto;
  }
  .submine {
    padding: 10px 0;
    padding-top: 50px;

    .van-button {
      height: 40px;
    }
  }
}
</style>
